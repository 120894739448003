<template>
  <div class="feedback">
    <div v-for="(val, index) in list" :key="index"  v-bind:class="{'run':index>3&&isrun}" class="feedback-detail">
      <div class="avatar">
        <img :src="val.avatar" alt="头像">
      </div>
      <div class="word">
        <span class="name" :style="rgba">{{val.name}}</span>
        <span class="grade">{{val.grade}}</span>
        <p>{{val.desc}}</p>
      </div>
    </div>
    <h5 class="title" v-show="title!='false'">家长<span :style="rgba">反馈</span></h5>
    <img class="bg-phone" :src="postImg" alt="家长反馈">
     <ul class="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "feedBack",
  props: {
    list: { // 内容
      type: Array,
      required: true
    },
    rgba: { // 主题颜色
      type: String,
      required: true
    },
    title: { // 是否复用title
      type: String,
      required: true
    },
    postImg: { // 背景图
      type: String,
      required: true
    }
  },
  data() {
    return {
      isrun:false
    }
  },
  mounted () {
    var box = document.querySelector(".feedback");
    var feedback=document.querySelector(".feedback-detail")
    let _that=this
      window.onscroll = function() {
        var top = document.documentElement.scrollTop || document.body.scrollTop;
        if(top > (box.offsetTop - box.offsetHeight)) {　　　　　　　　　
              _that.isrun=true     
             　　　　　　　　
          }
      }
  },
  methods: {

  }
};
</script>
<style scoped>
h5.title{
  font-size:50px;
  font-family:MicrosoftYaHei-Bold;
  font-weight:bold;
  color:rgba(68,65,68,1);
  text-align: center;
  margin-bottom: 60px;
}
.feedback{
  position: relative;
  height: 800px;
  overflow: hidden;
  background: transparent
}
.feedback h5{
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 28px;
}
.bg-phone{
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-59%);
  display: block;
  width: 606px;
  height: 680px;
  margin: 0 auto;
  z-index: 1;
}
.feedback-detail{
  width:460px;
  height:150px;
  background:rgba(255,255,255,1);
  box-shadow:1px 4px 0px 0px rgba(231,231,238,1);
  border-radius:16px;
  box-sizing: border-box;
  padding: 18px 20px 12px 20px;
  display: flex;
  position: absolute;
  /* visibility: hidden; */
  opacity: 0;
  text-align: left;
}
.run{
    animation: myshow 1s forwards;
  -webkit-animation: myshow 1s forwards;
}
/* 右下4 */
/* .feedback-detail:nth-child(1) {
  z-index: 0;
  top: 116px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 300px;
  background:rgb(245, 242, 242);
  opacity: 0.4;
  box-shadow:1px 3px 0px 0px rgba(214,214,223,0.6);
}
.feedback-detail:nth-child(2) {
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 500px; 
  top: 290px;
  background:rgb(245, 244, 244);
  box-shadow:1px 3px 0px 0px rgba(214,214,223,0.6);
  opacity: 0.3;
} */
.feedback-detail:nth-child(1) {
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 410px;
  top: 429px;
  background:rgb(233, 231, 231);
  box-shadow:1px 3px 0px 0px rgba(214,214,223,.6);
  opacity: 0.5;

}
.feedback-detail:nth-child(2) {
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 620px; 
  top: 611px;
  background:rgb(238, 236, 236);
  box-shadow:1px 3px 0px 0px rgba(214,214,223,0.6);
  opacity: 0.4;

}
/* 左下2 */
.feedback-detail:nth-child(3) {
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -590px; 
  top: 341px;
  background:rgba(246,243,243);
  box-shadow:1px 3px 0px 0px rgba(214,214,223,0.6);
  opacity: 0.7;

}
.feedback-detail:nth-child(4) {
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -510px; 
  top: 560px;
  background:rgba(238,238,238);
  box-shadow:1px 3px 0px 0px rgba(214,214,223,0.6);
  opacity: 0.4;

}
/* 右上3 */
.feedback-detail:nth-child(5) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: 250px;
  top: 217px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
   -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.feedback-detail:nth-child(6) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: 200px;
  top: 401px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
   -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.feedback-detail:nth-child(7) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: 310px;
  top: 583px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
   -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
/* 左上3 */
.feedback-detail:nth-child(8) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: -310px;
  top: 258px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
    -webkit-animation-delay:2s;
  animation-delay: 2s;
}
.feedback-detail:nth-child(9) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: -365px;
  top: 453px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
    -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.feedback-detail:nth-child(10) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: -210px;
  top: 626px;
  background:rgba(255,255,255,1);
  box-shadow:1px 3px 0px 0px rgba(231,231,238,0.6);
  z-index: 2;
    -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.avatar{
  margin-right: 18px;
}
.avatar img{
  width:50px;
  height:50px;
  display: block;
  border-radius:25px;
}
.feedback-detail .word{
  flex: 1;
}
.feedback-detail span{
  width: 100%;
  display: block;
}
span.name{
  font-size:20px;
  font-family:MicrosoftYaHei-Bold;
  font-weight:bold;
  line-height:30px;
}
span.grade{
  font-size:12px;
  font-family:MicrosoftYaHeiLight;
  font-weight:300;
  color:rgba(170,170,170,1);
  line-height:22px;
}
.feedback-detail p{
  width:100%;
  font-size:14px;
  font-family:PingFang-SC-Regular;
  font-weight:400;
  color:rgba(68,65,68,1);
  line-height:22px;
  display:-webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient:vertical;
overflow:hidden;
 text-overflow: ellipsis;
text-align: left;
height: 66px;
}
/* 泡泡 */
.bg-bubbles{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
}
.bg-bubbles li{
  position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(96, 130, 229, 0.15);
    bottom: -160px;
    -webkit-animation: square 20s infinite;
    animation: square 20s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
    left: 10%;
}
.bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 17s;
    animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
    animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
    left: 70%;
}
.bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
    animation-delay: 11s;
}
@-webkit-keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-800px) rotate(600deg);
        transform: translateY(-800px) rotate(600deg);
    }
}
@keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-800px) rotate(600deg);
        transform: translateY(-800px) rotate(600deg);
    }
}
@keyframes myshow{
  from{
    /* visibility: hidden; */
    opacity: 0;
  }
  to {
    /* visibility: visible; */
    opacity: 1;
  }
}
@-webkit-keyframes myshow{
   from{
    /* visibility: hidden; */
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
